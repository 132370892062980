<template>
  <div>
    <b-button
      v-if="canPostMedia"
      variant="outline-primary"
      class="add-media-button float-md-right"
      @click="isAddingMedia = true"
    >
      {{ $t('media.upload-media', [$tc('media.name.photo', 1)]) }}
    </b-button>
    <div class="d-flex flex-wrap justify-content-around">
      <div v-for="(photo, index) in photos" :key="photo.key" class="m-3">
        <photo-card :photo="photo" @click="handlePhotoCardClick($event, index)" />
      </div>
    </div>
    <photos-carousel-modal
      :visible.sync="isCarouselVisible"
      :value.sync="currentCarouselIndex"
      :photos="photos"
    />
    <media-upload-modal
      v-model="isAddingMedia"
      media-type="photo"
      :datos="getInitialData"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import mediaStoreModule from '@/views/apps/media/store/mediaStoreModule';
import {
  MEDIA_ACTIONS, MEDIA_STORE_MODULE_NAME,
} from '@/views/apps/media/constants/media-store-constants';
import PhotosCarouselModal from '@/views/apps/photos/components/PhotosCarouselModal.vue';
import PhotoCard from '@/views/apps/photos/components/PhotoCard.vue';
import MediaUploadModal from '@/views/apps/media/components/modal/MediaUploadModal.vue';
import MediaConfigurationMixin from '@/views/apps/media/mixins/MediaConfigurationMixin';

export default {
  components: {
    PhotoCard, PhotosCarouselModal, MediaUploadModal, BButton,
  },
  mixins: [MediaConfigurationMixin],
  data() {
    return {
      isCarouselVisible: false,
      currentCarouselIndex: 0,
      photos: [],
      isAddingMedia: false,
    };
  },
  computed:{
    canPostMedia() {
      return this.$store.getters.currentCollective.isStaff;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule(MEDIA_STORE_MODULE_NAME)) {
      this.$store.registerModule(MEDIA_STORE_MODULE_NAME, mediaStoreModule);
    }
  },
  created() {
    this.getInitialData();
  },
  methods: {
    async getInitialData() {
      const response = await this.$store.dispatch(MEDIA_ACTIONS.fetchMediaList, {
        page: 1,
        perPage: 16,
        mediaType: 'photo',
      });
      this.photos = response;
    },
    handlePhotoCardClick(_photo, index) {
      this.currentCarouselIndex = index;
      this.isCarouselVisible = true;
    },
  },
};
</script>
