<template>
  <b-card class="gallery-image mb-0" @click="$emit('click', photo)">
    <fluid-safe-img
      :src="image"
      :placeholder="placeholder"
      :alt="title"
      fluid
    />
    <div class="gallery-image__overlay">
      <div class="gallery-image__text">
        {{ title }}
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import { getImageResource } from '@/@core/utils/image-utils';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import FluidSafeImg from '@/@core/components/safe-img/FluidSafeImg.vue';
import PhotoPlaceholder from '@/assets/images/placeholders/light/general-placeholder.jpeg';

export default {
  name: 'PhotoCard',
  components: { BCard, FluidSafeImg },
  props: {
    photo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    image() {
      return getImageResource(this.photo.url);
    },
    title() {
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.photo.name);
    },
    placeholder() {
      return PhotoPlaceholder;
    },
  },
};
</script>

<style lang="scss" type="text/css">
@import "~@core/scss/base/components/gallery";
</style>
