export const MEDIA_STORE_MODULE_NAME = 'media';

export const MEDIA_ACTIONS = {
  fetchMediaList: `${MEDIA_STORE_MODULE_NAME}/fetchMediaList`,
  fetchMediaByKey: `${MEDIA_STORE_MODULE_NAME}/fetchMediaByKey`,
  postMedia: `${MEDIA_STORE_MODULE_NAME}/postMedia`,
  CreateCollaborativeDocuments: `${MEDIA_STORE_MODULE_NAME}/CreateCollaborativeDocuments`,
  updateMedia: `${MEDIA_STORE_MODULE_NAME}/updateMedia`,
  toggleLike: `${MEDIA_STORE_MODULE_NAME}/toggleLike`,
};

export const MEDIA_MUTATIONS = {
  updateMedia: `${MEDIA_STORE_MODULE_NAME}/updateMedia`,
};

export const MEDIA_GETTERS = {
  getPage: `${MEDIA_STORE_MODULE_NAME}/getPage`,
  getUntilPage: `${MEDIA_STORE_MODULE_NAME}/getUntilPage`,
  getTotal: `${MEDIA_STORE_MODULE_NAME}/getTotal`,
  getByKey: `${MEDIA_STORE_MODULE_NAME}/getByKey`,
};
