import Vue from 'vue';
import Service from '@/config/service-identifiers';
import store from '@/store';
import {
  getByKey, getPage, getTotal, storePage, getUntilPage, storeItem, prependItem, updateTotal, updateItemByKey,
} from '@/store/store-utils/StorageUtils';

/**
 * Media Store Module
 */
export default {
  namespaced: true,
  state: {},
  getters: {
    getByKey: (state) => (
      ({
        mediaType,
        key,
      }) => (
        getByKey(key, state, [mediaType])
      )
    ),
    getPage: (state) => (
      ({
        mediaType,
        page,
        perPage,
      }) => (
        getPage({ page, perPage }, state, [mediaType])
      )
    ),
    getUntilPage: (state) => (
      ({
        mediaType,
        page,
        perPage,
      }) => (
        getUntilPage({ page, perPage }, state, [mediaType])
      )
    ),
    getTotal: (state) => (mediaType) => getTotal(state, [mediaType]),
  },
  mutations: {
    storePage(state, {
      mediaType, data, meta,
    }) {
      storePage({ data, meta }, state, [mediaType]);
    },
    prependItem(state, { item, total, mediaType }) {
      prependItem(item, state, [mediaType]);
      updateTotal(total, state, [mediaType]);
    },
    updateMedia(state, { key, mediaType, partial }) {
      updateItemByKey(key, partial, state, [mediaType]);
    },
    storeByKey(state, { mediaType, item }) {
      storeItem(item, state, [mediaType]);
    },
    toggleLikeByKey(state, { key, mediaType }) {
      const item = getByKey(key, state, [mediaType]);
      Vue.set(item, 'likedByMember', !item.likedByMember);
      Vue.set(item, 'totalLikes', item.totalLikes + (item.likedByMember ? +1 : -1));
    },
  },
  actions: {
    async fetchMediaList(
      { commit, getters, rootGetters },
      {
        mediaType, page, perPage, force,
      },
    ) {
      const storedPage = getters.getPage({
        mediaType, page, perPage,
      });
      // if (storedPage && !force) {
      //   return storedPage;
      // }

      const response = await store.$service[Service.BackendClient].get(
        'media',
        {
          params: {
            communityKey: rootGetters.currentCollective.key,
            morphType: mediaType,
            page,
            count: perPage,
          },
        },
      );

      commit('storePage', { mediaType, ...response.data });

      return getters.getPage({
        mediaType, page, perPage,
      });
    },
    async fetchMediaByKey(
      { commit, getters, rootGetters },
      { key, mediaType, force },
    ) {
      const media = getters.getByKey({ key, mediaType });

      if (media && !force) {
        return;
      }

      const response = await store.$service[Service.BackendClient].get(
        'media',
        {
          params: {
            communityKey: rootGetters.currentCollective.key,
            morphType: mediaType,
            mediaKey: key,
          },
        },
      );

      if (!response.data.data[0]) {
        return;
      }

      commit('storeByKey', { mediaType, item: response.data.data[0] });
    },
        //Solo para media
        async toggleLike({ state, rootState }, { key, itemType, morphType, storedKey }) {
          const communityKey = rootState.collective.collective.key;
          let index, item, query;
          if (storedKey != null) {
            index = this.state[itemType][storedKey].storage.byKey[key];
            item = index
            query = item.likedByMember ? 'takeLike' : 'giveLike';
          }
    
          try {
            await store.$service[Service.BackendClient].post(
              query,
              { key, morphType, communityKey }
            );
          } catch (error) {
          }
        },
    async postMedia({ dispatch }, payload) {
      return dispatch('uploadMedia', payload);
    },
    async updateMedia({ dispatch }, payload) {
      return dispatch('uploadMedia', payload);
    },
    async uploadMedia({ commit, rootGetters }, {
      mediaKey, name, description, canDownload, isLink, linkURL, file, mediaType,
    }) {
      const method = mediaKey ? 'put' : 'post';
      const communityKey = rootGetters.currentCollective.key;

      let response;
      if (!isLink && file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('data', JSON.stringify({
          ...(mediaKey ? { mediaKey } : { communityKey }),
          name,
          description,
          canDownload,
          mediaType,
        }));

        response = await store.$service[Service.BackendClient][method](
          'media',
          formData,
          { headers: { 'content-type': 'multipart/form-data' } },
        );
      } else {
        response = await store.$service[Service.BackendClient][method](
          'media',
          {
            ...(mediaKey ? { mediaKey } : { communityKey }),
            name,
            description,
            canDownload,
            isLink,
            linkURL,
            mediaType,
          },
        );
      }

      const { data, total } = response.data;

      if (mediaKey) {
        setTimeout(() => {
          commit('updateMedia', {
            key: mediaKey,
            mediaType,
            partial: data[0],
          });
        }, 5000);
      } else {
        setTimeout(() => {
          commit('prependItem', {
            item: data[0],
            total,
            mediaType,
          });
        }, 5000);
      }

      return {
        items: data[0],
        total,
      };
    },
  },
};
